const getters = {};
const actions = {};
const state = {
  appSettings: {
    categoryLookup: {
      "Get Active": {
        icon: "active_icon",
        full: "active_full",
        titleLong: "Get Active in Melbourne",
        description:
          "From moonlit kayaking to hidden park trails, we reveal the most magical places to get moving in this city",
        color: "#c65828"
      },
      "Be Inspired": {
        icon: "inspired_icon",
        full: "inspired_full",
        titleLong: "Be Inspired by Melbourne",
        description:
          "Melbourne is home to some amazingly creative and inspiring people and places. Open your mind at the special experiences that we share with you.",
        color: "#fcd55f"
      },
      "Taste Melbourne": {
        icon: "food_marker",
        full: "food_full",
        titleLong: "Taste Melbourne",
        description:
          "Our home town is widely regarded as a gastronomic gem. Take your taste buds on an incredible journey through its unique flavours.",
        color: "#961b1e"
      }
    },

    socialIcon: {
      instagram: {
        icon: "mdi-instagram",
        color: "red lighten-3"
      },
      facebook: {
        icon: "mdi-facebook",
        color: "indigo"
      },
      twitter: {
        icon: "mdi-twitter",
        color: "#26c6da"
      },
      youtube: {
        icon: "mdi-youtube",
        color: "red"
      },
      tiktok: {
        icon: "mdi-alarm-multiple ",
        color: "black"
      },
      linkedin: {
        icon: "mdi-linkedin",
        color: "cyan darken-1"
      },
      pinterest: {
        icon: "mdi-pinterest",
        color: "pink"
      },
      generic: {
        icon: "mdi-link-variant",
        color: "green"
      }
    },

    validArSites: {
      "14": true,
      "23": true,
      "28B": true
    },
    autoOpenMoreInfo: true,
    drawWidth: 300,
    drawButtonWidth: "286px",
    mediaButtonColor: "#9ec0bd", //a6caa6
    appButtonColor: "#1f3531",
    dialogActionButtonColor: "#1f3531",

    menuBarColor: "#9ec0bd",
    audioPlayColor: "1f3531",
    drawWidthx: 300,
    headingTextColor: "font-weight-regular text--darken-1",
    styles: {
      //oldMarkerColours: ["#003953", "#607d8b", "#f28cb1", "#d8db76"],
      markerColours: ["#582110", "#f4afcf", "#582110", "#961a1d"],
      //oldmarkerText: "#f6e9d8",
      markerText: "#fcfbf1"
    }
  }
};
const mutations = {};
export default {
  state,
  getters,
  actions,
  mutations
};
