//import { _ } from "core-js";

const getters = {};
const actions = {
  controlsUpdateLayers({ commit }, value) {
    commit("UPDATE_LAYERS", value);
  }
};

const mutations = {
  UPDATE_LAYERS(state, value) {
    let oldValue = JSON.parse(JSON.stringify(state.layers));
    if (oldValue !== JSON.parse(JSON.stringify(value))) {
      state.layers = JSON.parse(JSON.stringify(value));
    }
  }
};

const state = {
  selectable: {},
  selected: {
    bools: { "Get Active": true, "Be Inspired": true, "Taste Melbourne": true }
  },

  defaultLayers: ["Get Active", "Be Inspired", "Taste Melbourne"],
  layers: ["Get Active", "Be Inspired", "Taste Melbourne"],
  setup: {
    bools: [
      {
        key: "Get Active",
        title: "Get Active",
        color: "#961a1d"
      },

      {
        key: "Be Inspired",
        title: "Be Inspired",
        color: "#f4afcf"
      },
      {
        key: "Taste Melbourne",
        title: "Taste Melbourne",
        color: "#582110"
      }
    ]
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
