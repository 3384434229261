<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="$vuetify.breakpoint.smAndDown ? '95%' : 900"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="secondary--text  ">
          About
          <font v-if="!$vuetify.breakpoint.xsOnly"> Maptivate Melbourne</font>
        </span>
      </template>

      <v-card>
        <v-card-title
          :style="{
            background: appSettings.menuBarColor
          }"
          class="  xlighten-2 secondary--text text-center justify-center siteHeadingText"
        >
          Maptivate Melbourne
        </v-card-title>
        <div :class="{ mobileSizeDialog: $vuetify.breakpoint.xsOnly }">
          <v-row no-gutters class="contentWrapper  secondary--text " style="">
            <v-spacer></v-spacer>

            <v-col md="8" cols="12" class="text-center justify-center">
              <v-card-text
                v-if="false"
                class=" secondary--text  text-center font-weight-medium  mb-0 pb-0"
              >
                Welcome to Melbourne!
              </v-card-text>
            </v-col>
            <v-spacer></v-spacer>

            <v-col md="7" cols="12">
              <center>
                <v-img
                  class="ma-5 center"
                  style="overflow:hidden;max-width:50%"
                  v-if="$vuetify.breakpoint.smAndDown"
                  :src="`/assets/landingPages/LANDING_PAGE_PHONE.png`"
                ></v-img>
                <v-img
                  class="ma-5"
                  v-if="!$vuetify.breakpoint.smAndDown"
                  :src="`/assets/landingPages/LANDING_PAGE_DESKTOP-08.png`"
                ></v-img>
              </center>
            </v-col>
            <v-col cols="12" md="5">
              <v-card-text
                class="black-text text-center font-weight-medium  mb-0 pb-0"
              >
                <v-card-text
                  class=" secondary--text  text-center font-weight-medium  mb-0 pb-0"
                >
                  <p>
                    Welcome to Maptivate Melbourne! A curated journey through
                    some of the best experiences that reveal the hidden gems and
                    local treasures of this beautiful city. Get active, taste
                    and be inspired as you journey with Maptivate powered by a
                    unique content delivery technology. Maptivate puts location
                    relevant information in all its forms right where you need
                    it. Enjoy the ride!
                  </p>
                </v-card-text>
              </v-card-text>

              <v-list three-line class="ma-0 pa-0 mr-2">
                <template
                  v-for="(category, catKey) in appSettings.categoryLookup"
                >
                  <v-list-item v-bind:key="catKey">
                    <v-list-item-avatar>
                      <v-img
                        v-if="true"
                        :src="`/icons/${category.full}.png`"
                      ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content class="secondary--text ">
                      <v-list-item-title>{{
                        category.titleLong
                      }}</v-list-item-title>

                      <v-list-item-subtitle
                        class="secondary--text font-weight-light text--lighten-1"
                        style="-webkit-line-clamp: 10;"
                        >{{ category.description }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <a
            href="http://maptivate.com.au/"
            target="_blank"
            style="text-decoration: none!important;"
          >
            <v-btn :color="appSettings.dialogActionButtonColor" text>
              More Information
              <font v-if="!$vuetify.breakpoint.smAndDown">About Maptivate</font>
            </v-btn>
          </a>
          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import { mapState } from "vuex";

export default {
  name: "AboutModal",

  data() {
    return {
      dialog: false,
      links: [
        {
          url: "https://www.theschooloflostarts.com.au/our-story",
          title: "The School of Lost Arts"
        },
        {
          url: "https://livesmartlab.deakin.edu.au",
          title: `Deakin University, <br>
          Live + Smart Lab and IT Dept. Launch Pad`
        },

        {
          url: "https://tract.com.au/who-we-are/",
          title: "Tract Consultants Geelong"
        },

        {
          url: "https://www.onemap.com.au/",
          title: "OneMap"
        },

        {
          url: "https://www.cartdeco.com.au/about",
          title: "CartDeco Cartographics, Geelong"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  created() {
    eventBus.$on("loadAboutDialog", () => {
      this.dialog = true;
    });
  },
  mounted() {
    //console.log("mounted!!!", this.openIntro);

    setTimeout(() => {
      this.dialog = true;
    }, 150);
  }
};
</script>

<style>
.contentWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}
@media only screen and (max-width: 960px) {
  .contentWrapper {
    max-height: calc(100vh - 280px);
  }
}

@media only screen and (max-width: 760px) {
  .contentWrapper {
    max-height: calc(100vh - 280px);
  }
}
</style>
