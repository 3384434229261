<template>
  <div class="youTubeLinks">
    <v-btn
      depressed
      v-if="youTubeUrlTitle"
      :color="appSettings.mediaButtonColor"
      :width="width"
      class="ma-2 white--text"
      @click="openYoutube(youTubeUrlTitle.url, properties.Point_Name)"
    >
      <v-icon left>mdi-youtube-tv</v-icon>
      {{ youTubeUrlTitle.title }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "VideoPlayButton",
  computed: {
    youTubeUrlTitle() {
      let youTubeUrl = this.properties.youTubeUrl;
      let title = false;

      if (this.altButton) {
        if (this.altButton == "2" && this.properties.youTubeUrl_2) {
          youTubeUrl = this.properties.youTubeUrl_2;
        } else if (this.altButton == "3" && this.properties.youTubeUrl_3) {
          youTubeUrl = this.properties.youTubeUrl_3;
        } else {
          youTubeUrl = false;
        }
      }

      if (youTubeUrl && youTubeUrl.includes("||")) {
        let tempUrl = youTubeUrl.split("||")[0].trim();
        title = youTubeUrl.split("||")[1].trim();
        youTubeUrl = tempUrl;
      }

      if (!title) {
        title = "Play Video";

        if (this.altButton == "2" && this.properties.youTubeUrl_2) {
          title = "Play 2nd Video";
        } else if (this.altButton == "3" && this.properties.youTubeUrl_3) {
          title = "Play 3rd Video";
        } else if (!this.altButton && this.properties.youTubeUrl_2) {
          title = "Play 1st Video";
        }
      }

      return { url: youTubeUrl, title };
    }
  },
  data: () => ({
    ready: false,
    audioPlayIcon: "false"
  }),
  props: {
    openYoutube: Function,
    width: String,
    appSettings: Object,
    properties: {
      required: true,
      type: Object
    },
    altButton: String
  },
  methods: {},
  async mounted() {}
};
</script>
<style>
.youTubeLinks .v-btn__content {
  width: 100%;
  white-space: normal;
}
</style>
