<template>
  <div>
    <v-card v-if="properties && properties.Point_Name" style="border-radius:0">
      <v-carousel
        v-if="imageFiles && imageFiles.length"
        hide-delimiters
        :show-arrows="imageFiles.length > 1"
        :height="imageSize[imageType] ? imageSize[imageType] : '100px'"
      >
        <v-carousel-item
          v-for="(item, i) in imageFiles"
          :key="i"
          :src="'./images/' + item"
        ></v-carousel-item>
      </v-carousel>
      <div class="sideBarTitle">
        <v-card-title
          class="secondary--text font-weight-regular text--darken-1"
          v-if="properties.Point_Name"
          >{{
            properties.Point_Numberx ? properties.Point_Numberx + " - " : ""
          }}
          {{ properties.Point_Name }}
        </v-card-title>
        <v-card-subtitle
          v-if="properties.Point_Artist || properties.Point_Address"
        >
          <font
            class="font-weight-normal secondary--text  text--lighten-1"
            v-if="properties.Point_Artist"
            >{{ properties.Point_Artist }}</font
          >
          <br v-if="properties.Point_Artist && properties.Point_Address" />
          <font
            class="font-weight-light  secondary--text text--lighten-1"
            v-if="properties.Point_Address"
            >{{ properties.Point_Address }}</font
          >
        </v-card-subtitle>
        <v-card-subtitle
          class="secondary--text font-weight-regular text--darken-1"
        >
          {{ appSettings.categoryLookup[properties.Category].titleLong }}
        </v-card-subtitle>
        <v-card-subtitle
          class="secondary--text font-weight-regular text--darken-1"
          v-for="item in subTitleToShow"
          :key="item.field"
          v-show="properties[item.field]"
          >{{ properties[item.field] }}
        </v-card-subtitle>
      </div>

      <AudioPlayButton
        :setSound="setSound"
        :getAudioDetails="getAudioDetails"
        :properties="properties"
        :width="appSettings.drawButtonWidth"
        :appSettings="appSettings"
      />

      <v-card-text
        class="py-0 xxgrey--text "
        v-for="item in detailsToShow"
        :key="item.field"
        v-show="properties[item.field]"
      >
        <span class="font-weight-medium text-uppercase headingText">
          {{ item.title }}:
        </span>

        <span class="" v-if="properties[item.field]">
          <a
            v-if="
              item.url &&
                properties[item.url] &&
                properties[item.url].includes('http')
            "
            :href="properties[item.url]"
            target="_blank"
            >{{ properties[item.field] }}</a
          >
          <font v-else>{{ properties[item.field] }}</font>
        </span>
      </v-card-text>

      <div v-if="Point_Description" class=" secondary--text mt-2">
        <v-card-text
          :class="[
            '',
            'pb-1',
            'pt-0',
            'Point_Description',
            showMore || !Point_Description_CanCrop ? 'showMore' : 'showLess'
          ]"
        >
          <span class="font-weight-medium text-uppercase headingText">
            Description:
          </span>

          <div v-html="Point_Description"></div>

          <div class="fade" v-if="!showMore && Point_Description_CanCrop"></div>
        </v-card-text>
        <v-card-text class="pt-0 accent--text ">
          <a
            @click="showMore = !showMore"
            v-if="Point_Description_CanCrop"
            class="accent--text"
            style="text-decoration: underline!important;"
          >
            <font v-if="showMore"> Show Less</font>
            <font v-if="!showMore"> Show More</font></a
          >
        </v-card-text>
      </div>
      <VideoPlayButton
        v-if="properties.youTubeUrl"
        :openYoutube="openYoutube"
        :properties="properties"
        :width="appSettings.drawButtonWidth"
        :appSettings="appSettings"
      />

      <VideoPlayButton
        v-if="properties.youTubeUrl_2"
        :openYoutube="openYoutube"
        :properties="properties"
        :width="appSettings.drawButtonWidth"
        :appSettings="appSettings"
        altButton="2"
      />

      <VideoPlayButton
        v-if="properties.youTubeUrl_3"
        :openYoutube="openYoutube"
        :properties="properties"
        :width="appSettings.drawButtonWidth"
        :appSettings="appSettings"
        altButton="3"
      />

      <PhotoSphereModal
        :width="appSettings.drawButtonWidth"
        :googleStreetIframe="properties.Google_Street_01"
        v-if="properties.Google_Street_01"
      />

      <v-card-text v-if="properties.linksAct && properties.linksAct.length">
        <span class="font-weight-medium text-uppercase headingText">
          LINKS:
        </span>
        <p
          v-for="link in properties.linksAct"
          :key="link.url"
          class="pa-0 ma-1 "
        >
          <a
            :href="link.url"
            target="_blank"
            style="text-decoration: underline!important;"
            class="accent--text"
          >
            {{ link.title }}
          </a>
        </p>
      </v-card-text>

      <v-card-text
        v-if="properties.socialsLinksAct && properties.socialsLinksAct.length"
      >
        <span class="font-weight-medium text-uppercase headingText">
          SOCIAL:
        </span>

        <div>
          <v-btn
            v-for="link in properties.socialsLinksAct"
            :key="link.url"
            light
            fab
            small
            :href="link.url"
            target="_blank"
            class="mx-1"
          >
            <v-icon :color="appSettings.socialIcon[link.title].color">{{
              appSettings.socialIcon[link.title].icon
            }}</v-icon>
          </v-btn>
        </div>
      </v-card-text>

      <v-card-text>
        <br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

import AudioPlayButton from "./../ui/AudioPlayButton.vue";
import VideoPlayButton from "./../ui/VideoPlayButton.vue";
import PhotoSphereModal from "./../controls/PhotoSphereModal.vue";

export default {
  name: "SidebarSiteInfo",
  components: {
    AudioPlayButton,
    VideoPlayButton,
    PhotoSphereModal
  },
  data: () => ({
    showMore: false,
    descriptionCropLength: 200,

    subTitleToShow: [
      /*{ field: "Category", title: "Category" }*/
    ],

    detailsToShow: [
      // { field: "Category", title: "Category" },
      //{ field: "Point_Number", title: "Site" },
      //{ field: "Point_Address", title: "Address" },
      { field: "Point_Artist", url: "Artist_URL", title: "Artist" }
      //      { field: "indexLabel", title: "Site Number" }
    ],
    imageSize: { t: "200px", s: "160px", w: "140px" }
  }),
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      markerList: state => state.markers.markerList,
      sidebarSite: state => state.navigation.sidebarSite,
      playingSiteName: state => state.media.playingSiteName,
      currentTrack: state => state.media.currentTrack,
      isPlaying: state => state.media.isPlaying
    }),
    /*
    isPlayingThisTrack() {
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === this.properties.Point_Name
      ) {
        return true;
      } else {
        return false;
      }
    },*/
    sites() {
      return this.markerList.data.features;
    },
    properties() {
      try {
        if (
          this.sites &&
          !isNaN(this.sidebarSite) &&
          this.sites[this.sidebarSite] &&
          this.sites[this.sidebarSite].properties
        ) {
          return this.sites[this.sidebarSite].properties;
        } else {
          //return null;
          throw { error: "error" };
        }
      } catch (error) {
        return null;
      }
    },
    imageFiles() {
      let imageFiles = this.properties.imageFiles;
      try {
        imageFiles = JSON.parse(imageFiles);
      } catch (error) {
        //do nothing
      }

      return imageFiles;
    },
    imageType() {
      return this.properties.imageType;
    },
    Point_Description() {
      return this.properties.Point_Description_Flat;
    },
    Point_Description_CanCrop() {
      return this.Point_Description.length > this.descriptionCropLength;
    },
    Point_Description_Croped() {
      return this.showMore === true || this.Point_Description_CanCrop === false
        ? this.Point_Description
        : this.Point_Description.slice(0, 150) + "... ";
    }
  },
  methods: {
    getAudioDetails(name, id) {
      let isPlayingThisTrack = false;
      let isLoadedThisTrack = false;
      if (
        this.isPlaying === true &&
        this.playingSiteName &&
        this.playingSiteName === name &&
        this.currentTrack === id
      ) {
        isPlayingThisTrack = true;
      }

      if (
        this.playingSiteName &&
        this.playingSiteName === name &&
        this.currentTrack === id
      ) {
        isLoadedThisTrack = true;
      }

      return {
        playingSiteName: this.playingSiteName,
        isPlaying: this.isPlaying,
        isPlayingThisTrack,
        isLoadedThisTrack
      };
    },
    openYoutube(youTubeAddress, Point_Name) {
      if (
        youTubeAddress.toLowerCase().includes("youtube") ||
        youTubeAddress.toLowerCase().includes("https://youtu")
      ) {
        this.$store.dispatch("mediaOpenYouTubeModal", {
          youTubeAddress,
          Point_Name
        });
      } else {
        window.open(youTubeAddress, "_blank");
      }
    },
    setSound(soundCloudId, Point_Name) {
      this.$store.dispatch("mediaSetTrackAndPlay", {
        soundCloudId,
        Point_Name
      });
    }
  },
  async mounted() {},
  watch: {
    sidebarSite: function(newValue) {
      this.showMore = false;

      if (newValue !== false || newValue === 0) {
        //this.$router.push({ name: "Home", params: { locationId: newValue } });
      }
    }
  }
};
</script>
<style>
.Point_Description h1 {
  font-size: 14px;
  text-transform: uppercase !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  line-height: 1.375rem !important;
  letter-spacing: 0.0071428571em !important;
  margin-top: 5px;
}

.Point_Description.showLess {
  max-height: 120px;
  overflow: hidden;
}

.Point_Description {
  position: relative;
}
.fade {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30px;
  width: 100%;
  background-image: linear-gradient(to bottom, transparent, white);
}

.v-card__subtitle + .v-card__subtitle {
  margin-top: -30px;
}
</style>
