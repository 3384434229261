var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(true)?_c('v-list',{staticClass:"ma-0 pa-0",attrs:{"three-line":""}},[_c('v-list-item',{on:{"click":function($event){$event.preventDefault();return _vm.loadIntro()}}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":"/assets/landingPages/LANDING_PAGE_PHONE.png"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s('Acknowledgement of Country')}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s('Welcome to Melbourne!')}})],1)],1)],1):_vm._e(),(!_vm.layers.length)?_c('center',[_c('br'),_c('div',{staticClass:"ml-4 heading right"},[_vm._v("... no items")])]):_vm._e(),(!_vm.layers.length)?_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"depressed":"","color":_vm.appSettings.mediaButtonColor,"width":_vm.appSettings.drawButtonWidth},on:{"click":function($event){return _vm.resetFilters()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cached")]),_vm._v(" Reset category filters ")],1):_vm._e(),_c('v-list',{staticClass:"ma-0 pa-0",attrs:{"three-line":""}},[_c('v-list-item-group',{staticClass:"ma-0 pa-0",attrs:{"active-class":"error--text"},model:{value:(_vm.openPopupSiteAct),callback:function ($$v) {_vm.openPopupSiteAct=$$v},expression:"openPopupSiteAct"}},[_vm._l((_vm.sites),function(site,index){return [(
            _vm.layers.includes(site.properties.Category) &&
              (!_vm.sites[index - 1] ||
                (_vm.sites[index - 1] &&
                  _vm.sites[index - 1].properties.Category !=
                    _vm.sites[index].properties.Category))
          )?_c('div',{key:'heading_' + site.properties.index},[_c('div',{staticClass:"ml-4 heading"},[_vm._v(" "+_vm._s(_vm.appSettings.categoryLookup[site.properties.Category].titleLong)+" ")]),_c('v-divider',{staticClass:"mt-3"})],1):_vm._e(),(_vm.layers.includes(site.properties.Category))?_c('v-list-item',{key:site.properties.index,class:[
            'siteKeyAct_' + site.properties.index,
            _vm.openPopupSite === site.properties.index ? 'currentlyOpen' : ''
          ],on:{"click":function($event){$event.preventDefault();return _vm.focusOnSite(site.properties.index)},"dblclick":function($event){return _vm.loadMoreInfo(site.properties.index)}}},[_c('v-list-item-avatar',[(true)?_c('v-img',{attrs:{"src":("/icons/" + (_vm.appSettings.categoryLookup[site.properties.Category].full) + ".png")}}):_vm._e()],1),_c('v-list-item-content',{staticClass:"secondary--text "},[_c('v-list-item-title',[_vm._v(_vm._s(site.properties.Site_Marker_Number)+_vm._s(site.properties.Site_Marker_Number ? " - " : "")+_vm._s(site.properties.Point_Name))]),(site.properties.Point_Artist)?_c('v-list-item-subtitle',{staticClass:"secondary--text font-weight-normal text--lighten-1"},[_vm._v(_vm._s(site.properties.Point_Artist)+" ")]):_vm._e(),_vm._v(" "+_vm._s(site.properties.Sound_Cloud_ShareID)+" "),(site.properties.Point_Address)?_c('v-list-item-subtitle',{staticClass:"secondary--text font-weight-light text--lighten-3"},[_vm._v(_vm._s(site.properties.Point_Address)+" ")]):_vm._e(),_c('v-list-item-subtitle')],1)],1):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }