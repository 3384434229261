export default new (class {
  async renderArtWork(map, appSettings) {
    if (appSettings) {
      //do nothing;
    }

    //const artwork = [{}];

    map.loadImage("/assets/Untitled_Artwork47.png", (error, image) => {
      if (error) throw error;

      // Add the image to the map style.
      map.addImage("cat", image);

      // Add a data source containing one point feature.
      map.addSource("artWork", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [144.9631, -37.8]
              }
            }
          ]
        }
      });

      // Add a layer to use the image to represent the data.
      map.addLayer({
        id: "artWork",
        type: "symbol",
        source: "artWork", // reference the data source
        layout: {
          "icon-image": "cat", // reference the image
          //"icon-size": 0.25,
          "icon-rotate": 180,
          "icon-size": ["interpolate", ["linear"], ["zoom"], 15, 0.2, 19, 0.5]
        }
      });
    });
  }
  async createActivityIcons(map, imageList, baseurllocation) {
    //this dynamicly loads 60 images for each of the project marker
    var N = 60;
    try {
      N = imageList.length;
      //N = markerList.data.features.length + 1;
    } catch (error) {
      N = 60;
    }

    const arr = [...Array(N + 1).keys()].slice(1);

    // this creates a blank image while the icons load.
    var width = 1;
    var bytesPerPixel = 4;
    var blankImage = new Uint8Array(width * width * bytesPerPixel);
    for (var x = 0; x < width; x++) {
      for (var y = 0; y < width; y++) {
        var offset = (y * width + x) * bytesPerPixel;
        blankImage[offset + 0] = 1; // red
        blankImage[offset + 1] = 1; // green
        blankImage[offset + 2] = 1; // blue
        blankImage[offset + 3] = 0; // alpha
      }
    }

    var genIcon = async function(map, blankImage, width, imageId) {
      //add dummy image
      map.addImage(imageId, {
        width: width,
        height: width,
        data: blankImage
      });
      await map.loadImage(
        `${window.location.origin}${baseurllocation}/icons/${imageId}.png`,
        async (error, image) => {
          if (error) throw error;
          map.removeImage(imageId); //remove dummy image
          //add loaded image
          map.addImage(imageId, image, { pixelRatio: 2 });
        }
      );
    };

    //genIcon(map, blankImage, width, imageId);

    const loadNumberIconsLoop = async () => {
      for (let index = 0; index < arr.length; index++) {
        // Get num of each fruit
        //let imageId = "icon" + arr[index];

        let imageId = imageList[index];
        genIcon(map, blankImage, width, imageId);
      }
    };

    await loadNumberIconsLoop();
  }

  /* 
  async createIcons(map, markerList, baseurllocation) {
    //this dynamicly loads 60 images for each of the project marker
    var N = 60;
    try {
      N = markerList.data.features.length + 1;
    } catch (error) {
      N = 60;
    }
    const arr = [...Array(N + 1).keys()].slice(1);

    // this creates a blank image while the icons load.
    var width = 1;
    var bytesPerPixel = 4;
    var blankImage = new Uint8Array(width * width * bytesPerPixel);
    for (var x = 0; x < width; x++) {
      for (var y = 0; y < width; y++) {
        var offset = (y * width + x) * bytesPerPixel;
        blankImage[offset + 0] = 1; // red
        blankImage[offset + 1] = 1; // green
        blankImage[offset + 2] = 1; // blue
        blankImage[offset + 3] = 0; // alpha
      }
    }

    var genIcon = async function(map, blankImage, width, number) {
      //add dummy image
      map.addImage(number, {
        width: width,
        height: width,
        data: blankImage
      });
      await map.loadImage(
        `${window.location.origin}${baseurllocation}/icons/${number}.png`,
        async (error, image) => {
          if (error) throw error;
          map.removeImage(number); //remove dummy image
          //add loaded image
          map.addImage(number, image, { pixelRatio: 2 });
        }
      );
    };

    genIcon(map, blankImage, width, "icon0");

    const loadNumberIconsLoop = async () => {
      for (let index = 0; index < arr.length; index++) {
        // Get num of each fruit
        let number = "icon" + arr[index];
        genIcon(map, blankImage, width, number);
      }
    };

    await loadNumberIconsLoop();
  }
*/

  renderMarkersCircles(map, appSettings) {
    // the marker icon:

    let useIcons = true;
    if (useIcons) {
      map.addLayer({
        id: "places",
        type: "symbol",
        source: "places",
        minzoom: 1,
        //filter: ["==", "hasStacking", false],

        paint: {
          "text-color": appSettings.styles.markerText,

          "text-opacity": {
            stops: [
              [12, 0],
              [13, 1]
            ]
          }
        },
        layout: {
          "text-field": ["get", "Site_Marker_Number"],
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": {
            stops: [
              [13, 2 * 3],
              [14, 2 * 6],
              [15, 2 * 8],
              [16, 2 * 10],
              [20, 2 * 12] //30
            ]
          },
          "text-offset": [0, -0.4],
          "text-anchor": "bottom",
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "text-allow-overlap": true,
          "text-ignore-placement": true,
          //"icon-allow-overlap": false,
          //"icon-anchor": "bottom",
          //"icon-offset":"bottom",
          "icon-image": [
            "match",
            ["get", "Category"],
            "Get Active",
            "active_icon",
            "Be Inspired",
            "inspired_icon",
            "Taste Melbourne",
            "food_marker",
            /* other */ "active_icon"
          ],
          //"icon-size": 0.25,
          //"icon-rotate": 180,
          "icon-size": {
            stops: [
              [13, 0.08 * 3],
              [14, 0.08 * 6],
              [15, 0.08 * 8],
              [16, 0.08 * 10],
              [20, 0.08 * 12] //30
            ]
          }
        }
      });

      /*
      map.addLayer({
        id: "places_infoWindowsTarget",
        type: "circle",
        source: "places",
        minzoom: 1,
        //filter: ["==", "hasStacking", false],
        layout: {
          //"text-allow-overlap": true,          

        },
        paint: {
          "circle-color": "red",
          'circle-translate':{
            stops: [
              [13, [0,  -3]],
              [14, [0, -6]],
              [15, [0, -10]],
              [16, [0, -16*2]],
              [20, [0, -32*2]] //30
            ]
          },
          "circle-radius": {
            stops: [
              [13, 2 * 3],
              [14, 2 * 6],
              [15, 2 * 10],
              [16, 2 * 16],
              [20, 2 * 16] //30
            ]
          }
        }
      });

*/
    } else {
      map.addLayer({
        id: "places-stackedCopy",
        type: "circle",
        source: "places",
        minzoom: 1,
        filter: [">", "stackIndex", 0],
        paint: {
          "circle-color": appSettings.styles.markerColours[1], //,
          "circle-radius": {
            stops: [
              [13, 4 * 1.25],
              [15, 10 * 1.25],
              [17, 16 * 1.25],
              [20, 30 * 1.25]
            ]
          }
        }
      });

      map.addLayer({
        id: "places",
        type: "circle",
        source: "places",
        minzoom: 1,
        filter: ["==", "hasStacking", false],
        paint: {
          "circle-color": [
            "match",
            ["get", "Category"],
            "Get Active",
            appSettings.styles.markerColours[3],
            "Be Inspired",
            appSettings.styles.markerColours[1],
            "Taste Melbourne",
            appSettings.styles.markerColours[2],
            /* other */ "red"
          ],

          "circle-radius": {
            stops: [
              [13, 4],
              [15, 10],
              [17, 16],
              [20, 30]
            ]
          }
        }
      });

      //numbers
      map.addLayer({
        id: "place-numbers",
        type: "symbol",
        source: "places",
        minzoom: 14,
        filter: ["==", "hasStacking", false],
        layout: {
          "text-field": ["get", "indexLabel"],
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": {
            stops: [
              [13, 4],
              [15, 10],
              [17, 16],
              [20, 30]
            ]
          },

          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "text-allow-overlap": true,
          "text-ignore-placement": true
        },
        paint: {
          "text-color": appSettings.styles.markerText,

          "text-opacity": {
            stops: [
              [13, 0],
              [14, 1]
            ]
          }
        }
      });
      map.addLayer({
        id: "place-numbers-stacked",
        type: "symbol",
        source: "places",
        minzoom: 14,
        filter: ["==", "hasStacking", true],
        layout: {
          "text-field": ["get", "stackedIndexLabel"],
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": {
            stops: [
              [13, 4],
              [15, 10 * 0.8],
              [17, 16 * 0.8],
              [20, 30 * 0.8]
            ]
          },
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "text-allow-overlap": true,
          "text-ignore-placement": true
        },
        paint: {
          "text-color": appSettings.styles.markerText,
          "text-opacity": {
            stops: [
              [13, 0],
              [14, 1]
            ]
          }
        }
      });
    }
  }
})();
